// import "bootstrap/js/dist/alert";
// import "bootstrap/js/dist/button";
// import "bootstrap/js/dist/carousel";
// import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/modal";
// import "bootstrap/js/dist/popover";
// import "bootstrap/js/dist/scrollspy";
// import "bootstrap/js/dist/tab";
// import "bootstrap/js/dist/toast";
// import "bootstrap/js/dist/tooltip";

import { events } from "./utillites.js";
import { slider_nav } from "./slider_nav.js";
import { slider_9 } from "./slider_9.js";
import { slider_8 } from "./slider_8.js";
import { slider_7 } from "./slider_7.js";
import { slider_6 } from "./slider_6.js";
import { slider_5 } from "./slider_5.js";
import { slider_4 } from "./slider_4.js";
import { slider_3 } from "./slider_3.js";
import { slider_2 } from "./slider_2.js";
import { slider_1 } from "./slider_1.js";
import { service_worker } from "./service_worker.js";
import { fallback } from "./fallback.js";

events(window, "load", () => {
  slider_nav();
  slider_9();
  slider_8();
  slider_7();
  slider_6();
  slider_5();
  slider_4();
  slider_3();
  slider_2();
  slider_1();
  service_worker();
  fallback();
});